import {
  Inject,
  Injectable,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { UserProfile } from '../auth/models/user-profile.model';
import { EnvironmentInterface } from '../models/environment.interface';
import { ENVIRONMENT } from '../models/environment.token';

import { EnvConfigService } from './env-config.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private readonly authService: AuthService,
    private readonly envConfigService: EnvConfigService,
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentInterface,
  ) {}

  initApp(): Observable<UserProfile> {
    this.setAppUrls();

    return this.envConfigService.getEnvironmentConfiguration().pipe(
      switchMap(config => this.authService.init(config)),
      switchMap(userProfile => {
        if (userProfile) {
          return [userProfile];
        } else {
          // No user. Abort start of the application and redirect to login
          return throwError(
            () => new Error('Initialization failed: UserProfile is null'),
          );
        }
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  private setAppUrls(): void {
    const appUrl = window.location.origin;
    this.environment.appUrl = appUrl;
    this.environment.appApiUrl = `${appUrl}/api/`;
    this.environment.apiUrl = `${appUrl}/sandbox`;
  }
}

export const appInitializerProvider = provideAppInitializer(() => {
  const initializerFn = (
    (
      appInitializerService: AppInitializerService,
    ): (() => Observable<UserProfile>) =>
    () =>
      appInitializerService.initApp()
  )(inject(AppInitializerService));
  return initializerFn();
});
