import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-92c4a1ea.js';
import { M as MultiChildrenWrapper } from './p-18c82265.js';
import { i as isSameArray } from './p-d7a5de56.js';
import { ai as isDefined } from './p-a57042e3.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-de830ba7.js';
import { d as defineCustomElement$i } from './p-49749ba7.js';
import { d as defineCustomElement$h } from './p-35b617b4.js';
import { d as defineCustomElement$g } from './p-69451510.js';
import { d as defineCustomElement$f } from './p-d7980c17.js';
import { d as defineCustomElement$e } from './p-8bd1db02.js';
import { d as defineCustomElement$d } from './p-8bfe5ab0.js';
import { d as defineCustomElement$c } from './p-0585e0e2.js';
import { d as defineCustomElement$b } from './p-25396692.js';
import { d as defineCustomElement$a } from './p-3a198e00.js';
import { d as defineCustomElement$9 } from './p-21c18302.js';
import { d as defineCustomElement$8 } from './p-7bb98ead.js';
import { d as defineCustomElement$7 } from './p-867a1c3b.js';
import { d as defineCustomElement$6 } from './p-96ee8029.js';
import { d as defineCustomElement$5 } from './p-a83b6d5f.js';
import { d as defineCustomElement$4 } from './p-106f8152.js';
import { d as defineCustomElement$3 } from './p-c5978c85.js';
import { d as defineCustomElement$2 } from './p-096bd431.js';
import { d as defineCustomElement$1 } from './p-c953c88d.js';
const selectDropdownCss = ".sc-owc-select-dropdown-h{display:-ms-inline-flexbox;display:inline-flex;width:calc(var(--one-spacing-size-7-xs) * 250)}.owc-select-dropdown--loading.sc-owc-select-dropdown-h owc-list-item.sc-owc-select-dropdown{visibility:hidden}";
const OwcSelectDropdownStyle0 = selectDropdownCss;
const MENU_ITEM_LIMIT = 6;
const SelectDropdown = /*@__PURE__*/proxyCustomElement(class SelectDropdown extends H {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.baseDropdownRef = useRef();
    this.component = new ComponentUtils(this, {
      patchFocusMethod: () => this.focusDropdownElement(),
      patchBlurMethod: () => this.blurDropdownElement()
    });
    this.focusDropdownElement = () => {
      var _a;
      if (!this.disabled && !this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        this.focused = true;
      }
    };
    this.blurDropdownElement = () => {
      var _a;
      if (this.focused) {
        (_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        this.focused = false;
      }
    };
    this.visible = false;
    this.validity = {
      state: 'valid'
    };
    this.disabled = false;
    this.label = undefined;
    this.limit = MENU_ITEM_LIMIT;
    this.name = undefined;
    this.placeholder = undefined;
    this.required = false;
    this.value = [];
    this.variant = 'filled';
    this.size = 'medium';
    this.rounded = false;
    this.searchable = false;
    this.notFoundText = 'Not Found';
    this.noClearIcon = false;
    this.multiple = false;
    this.selectAllText = 'All';
    this.hideSelectAll = false;
    this.showApplyButton = false;
    this.minlength = undefined;
    this.maxlength = undefined;
    this.controlled = false;
    this.notSelectableValues = [];
    this.disableValidation = false;
    this.validationTrigger = 'input, blur';
    this.controlledValidity = false;
    this.selectableValues = [];
    this.applyButtonText = 'Apply';
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.tooltipProps = undefined;
    this.clearButtonProps = undefined;
    this.menuTriggerButtonProps = undefined;
    this.focused = false;
    this.loading = true;
    this.internalValue = [];
  }
  onValueChanged() {
    let newInternalValue = [];
    Array.isArray(this.value) && (newInternalValue = this.value);
    typeof this.value === 'string' && (newInternalValue = this.value.split(',').map(v => v.trim()));
    newInternalValue = newInternalValue.filter(isDefined);
    !isSameArray(this.internalValue, newInternalValue) && (this.internalValue = newInternalValue);
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    return Promise.resolve((_b = (_a = this === null || this === void 0 ? void 0 : this.baseDropdownRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.validate());
  }
  componentWillLoad() {
    this.onValueChanged();
  }
  componentDidLoad() {
    this.loading = false;
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      loading: this.loading
    });
    return h(Host, {
      key: '88fc9d8359155a275c55b87a7a738ea4988737ae',
      class: hostClasses,
      tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex()
    }, h("owc-base-dropdown", {
      key: 'f9e4133394bcbe6d20bbab9dd8341d6d8b632a43',
      ref: this.baseDropdownRef,
      visible: this.visible,
      validity: this.validity,
      disableValidation: this.disableValidation,
      disabled: this.disabled,
      label: this.label,
      limit: this.limit,
      name: this.name,
      placeholder: this.placeholder,
      required: this.required,
      value: this.internalValue,
      controlled: this.controlled,
      variant: this.variant,
      size: this.size,
      rounded: this.rounded,
      searchable: this.searchable,
      notFoundText: this.notFoundText,
      noClearIcon: this.noClearIcon,
      multiple: this.multiple,
      selectAllText: this.selectAllText,
      hideSelectAll: this.hideSelectAll,
      showApplyButton: this.showApplyButton,
      applyButtonText: this.applyButtonText,
      minlength: this.minlength,
      maxlength: this.maxlength,
      selectableValues: this.selectableValues,
      notSelectableValues: this.notSelectableValues,
      validationTrigger: this.validationTrigger,
      controlledValidity: this.controlledValidity,
      inputProps: this.inputProps,
      menuProps: this.menuProps,
      tooltipProps: this.tooltipProps,
      clearButtonProps: this.clearButtonProps,
      menuTriggerButtonProps: this.menuTriggerButtonProps,
      onValueChange: e => {
        if (!isSameArray(this.internalValue, e.detail)) {
          !this.controlled && (this.value = e.detail);
          this.valueChange.emit(e.detail);
        }
      },
      onVisibleChange: e => this.visibleChange.emit(e.detail),
      onClickOutside: () => this.clickOutside.emit(),
      onValidityChange: e => this.validityChange.emit(e.detail),
      onClearClick: () => this.clearClick.emit(),
      onFocus: () => {
        this.focused = true;
        this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      },
      onBlur: () => {
        this.focused = false;
        this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      }
    }, h("slot", {
      key: 'b8f0b17c4b2a674d6140a8e08fe8131e6f42dbe8',
      name: "prefix"
    }), h("slot", {
      key: '6562ac1f5874c50901b598c577f5209e2e755373',
      name: "suffix"
    }), h("slot", {
      key: '862dffbd9880378651d29e12e7e4f1fa1c2be12b',
      name: "assistive-text"
    }), h(MultiChildrenWrapper, {
      key: '991c93e0cdcb9219e83d8cd95cdccb8581b69372'
    }, h("slot", {
      key: '8aaa1935dcf0a3cec38c060188199e42d91fd519'
    }))));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "value": ["onValueChanged"]
    };
  }
  static get style() {
    return OwcSelectDropdownStyle0;
  }
}, [6, "owc-select-dropdown", {
  "visible": [1540],
  "validity": [16],
  "disabled": [516],
  "label": [513],
  "limit": [514],
  "name": [513],
  "placeholder": [513],
  "required": [516],
  "value": [1],
  "variant": [513],
  "size": [513],
  "rounded": [516],
  "searchable": [516],
  "notFoundText": [513, "not-found-text"],
  "noClearIcon": [516, "no-clear-icon"],
  "multiple": [516],
  "selectAllText": [513, "select-all-text"],
  "hideSelectAll": [4, "hide-select-all"],
  "showApplyButton": [516, "show-apply-button"],
  "minlength": [514],
  "maxlength": [514],
  "controlled": [516],
  "notSelectableValues": [16],
  "disableValidation": [516, "disable-validation"],
  "validationTrigger": [513, "validation-trigger"],
  "controlledValidity": [516, "controlled-validity"],
  "selectableValues": [16],
  "applyButtonText": [513, "apply-button-text"],
  "inputProps": [16],
  "menuProps": [16],
  "tooltipProps": [16],
  "clearButtonProps": [16],
  "menuTriggerButtonProps": [16],
  "focused": [32],
  "loading": [32],
  "internalValue": [32],
  "validate": [64]
}, undefined, {
  "value": ["onValueChanged"]
}]);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-select-dropdown", "owc-base-dropdown", "owc-button", "owc-card", "owc-checkbox", "owc-component-footer", "owc-component-header", "owc-divider", "owc-focus-trap", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip", "owc-typography"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-select-dropdown":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, SelectDropdown);
        }
        break;
      case "owc-base-dropdown":
        if (!customElements.get(tagName)) {
          defineCustomElement$i();
        }
        break;
      case "owc-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$h();
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$g();
        }
        break;
      case "owc-checkbox":
        if (!customElements.get(tagName)) {
          defineCustomElement$f();
        }
        break;
      case "owc-component-footer":
        if (!customElements.get(tagName)) {
          defineCustomElement$e();
        }
        break;
      case "owc-component-header":
        if (!customElements.get(tagName)) {
          defineCustomElement$d();
        }
        break;
      case "owc-divider":
        if (!customElements.get(tagName)) {
          defineCustomElement$c();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$b();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$a();
        }
        break;
      case "owc-icon-button":
        if (!customElements.get(tagName)) {
          defineCustomElement$9();
        }
        break;
      case "owc-input":
        if (!customElements.get(tagName)) {
          defineCustomElement$8();
        }
        break;
      case "owc-list":
        if (!customElements.get(tagName)) {
          defineCustomElement$7();
        }
        break;
      case "owc-list-item":
        if (!customElements.get(tagName)) {
          defineCustomElement$6();
        }
        break;
      case "owc-menu":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-radio":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-tooltip":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
      case "owc-typography":
        if (!customElements.get(tagName)) {
          defineCustomElement$1();
        }
        break;
    }
  });
}
export { SelectDropdown as S, defineCustomElement as d };