import { p as proxyCustomElement, H, d as createEvent, r as readTask, h, c as Host } from './p-92c4a1ea.js';
import { a as FocusTrapQueryBuilder } from './p-20d8baec.js';
import { Z as doWhenDefaultIsNotPrevented, V as findElementInEventPath, b as generateElementId, a7 as KeyboardCode, g as getDocument, J as isElementReady } from './p-a57042e3.js';
import { a as accessibilityAttributes, b as accessibility } from './p-756e7dc5.js';
import { u as useRef } from './p-e952b680.js';
import { a as ComponentUtils } from './p-de830ba7.js';
import { a as Context, C as ContextValue } from './p-f5bb9925.js';
import { C as CTX_KEYS } from './p-16b4fd0e.js';
import { d as defineCustomElement$5 } from './p-69451510.js';
import { d as defineCustomElement$4 } from './p-25396692.js';
import { d as defineCustomElement$3 } from './p-3a198e00.js';
import { d as defineCustomElement$2 } from './p-867a1c3b.js';
import { d as defineCustomElement$1 } from './p-106f8152.js';
const menuCss = ".sc-owc-menu-h{max-width:calc(var(--one-spacing-size-7-xs) * 312);min-width:calc(var(--one-spacing-size-7-xs) * 110);z-index:var(--one-z-index-popover);background-color:var(--one-generic-background-transparent);border-radius:var(--one-spacing-radius-5-xs);display:contents;outline:none;overflow:visible}.owc-menu--loading.sc-owc-menu-h{visibility:hidden}.owc-menu--loading.sc-owc-menu-h owc-list-item.sc-owc-menu{visibility:hidden}.sc-owc-menu-h owc-popover.sc-owc-menu{min-width:inherit;max-width:inherit;width:inherit;-webkit-box-shadow:inherit;box-shadow:inherit;z-index:inherit;background-color:inherit;border-radius:inherit;border:inherit}@media (forced-colors: active){.sc-owc-menu-h owc-popover.sc-owc-menu{outline:none}}";
const OwcMenuStyle0 = menuCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = undefined && undefined.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
const MENU_ITEM_LIMIT = 6;
const LIST_ITEM_SELECTOR = 'owc-list-item';
const TIME_INTERVAL = 20;
const NUMBER_OF_ATTEMPTS = 100;
const OWC = 'owc';
const NATIVE_ELEMENT_QUERY = FocusTrapQueryBuilder.getNativeElementQuery().build();
const Menu = /*@__PURE__*/proxyCustomElement(class Menu extends H {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.ctxMenuStatus = new ContextValue();
    this.listElement = useRef();
    this.component = new ComponentUtils(this);
    this.isClickable = el => !el.disabled && !el.parentDisabled && el.leaf && !el.hostHidden;
    this.setCtxStatus = s => this.ctxMenuStatus.set(Object.assign(Object.assign({}, this.ctxMenuStatus.get()), s));
    this.onListClick = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      const composedPath = [...ev.composedPath()];
      doWhenDefaultIsNotPrevented(ev, () => {
        const item = findElementInEventPath(composedPath, LIST_ITEM_SELECTOR);
        if (item && this.isClickable(item)) {
          this.autoCloseOnSelection && (this.visible = false);
        }
      });
    };
    this.onListSelectedChanged = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      doWhenDefaultIsNotPrevented(ev, () => this.autoCloseOnSelection && (this.visible = false));
    };
    this.hostId = generateElementId(this.hostElement);
    this.anchor = undefined;
    this.usage = 'standalone';
    this.position = 'bottom-start';
    this.limit = MENU_ITEM_LIMIT;
    this.visible = false;
    this.notFocusable = false;
    this.preventVisibilityChangeOnAnchorBlur = false;
    this.autoCloseOnSelection = false;
    this.strategy = 'fixed';
    this.trigger = 'click';
    this.offset = undefined;
    this.listProps = undefined;
    this.cardProps = undefined;
    this.anchorWidth = false;
    this.preventOverflow = false;
    this.noKeydownManager = false;
    this.focusedElementId = '';
    this.autoFocusTo = 'first';
    this.loading = true;
  }
  onLimitChange() {
    this.reflectLimitChanges();
  }
  visibleWatcher(newValue, oldValue) {
    !!newValue !== !!oldValue && this.visibleChange.emit(!!newValue);
    if (this.visible) {
      this.reflectLimitChanges();
      this.updateWidth();
    } else {
      this.autoFocusTo = 'first';
    }
    this.setCtxStatus({
      visible: this.visible
    });
  }
  onAnchorChanged() {
    this.updateWidth();
    this.initKeydownManager();
  }
  onLoadingChanged() {
    var _a, _b, _c;
    !this.loading && !this.focusedElementId && ((_c = (_b = (_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.then(manager => {
      var _a;
      this.focusedElementId = (_a = manager.getCurrentItem()) === null || _a === void 0 ? void 0 : _a.id;
    }));
  }
  componentDidLoad() {
    this.setCtxStatus({
      visible: this.visible
    });
    this.reflectLimitChanges();
    this.onAnchorChanged();
    this.onLoadingChanged();
  }
  componentWillRender() {
    this.updateAriaAttributes();
  }
  initKeydownManager() {
    this.component.withKeydownManagerLazy(() => this.getAnchorElement(), [{
      keydownHandlers: [{
        key: [KeyboardCode.ArrowDown],
        handler: () => {
          this.autoFocusTo = 'first';
          this.visible = true;
        },
        disabled: () => this.visible || this.noKeydownManager
      }, {
        key: [KeyboardCode.ArrowUp],
        handler: () => {
          this.autoFocusTo = 'last';
          this.visible = true;
        },
        disabled: () => this.visible || this.noKeydownManager
      }]
    }]);
  }
  getAnchorElement() {
    var _a;
    return typeof this.anchor === 'string' ? (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.getElementById(this.anchor) : this.anchor;
  }
  getAnchorElementId() {
    const anchor = this.getAnchorElement();
    if (anchor) {
      !anchor.id && (anchor.id = generateElementId(anchor));
      return anchor.id;
    }
  }
  getListItems(src) {
    return Array.from((src || this.hostElement).querySelectorAll(LIST_ITEM_SELECTOR));
  }
  getLiElements() {
    var _a;
    return Array.from(((_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(`${LIST_ITEM_SELECTOR} > .owc-list-item__list-item-container`)) || []);
  }
  reflectLimitChanges() {
    if (this.visible) {
      isElementReady(() => this.getLiElements().some(item => item.offsetHeight > 0), NUMBER_OF_ATTEMPTS, TIME_INTERVAL).then(() => {
        var _a;
        const childrenNodes = this.getLiElements();
        const totalHeight = childrenNodes.slice(0, this.limit).reduce((sum, item) => sum + (item.offsetHeight || 0), 0);
        const itemHeight = childrenNodes.find(listItem => listItem.offsetHeight > 0);
        const maxHeight = Math.max(totalHeight, (itemHeight === null || itemHeight === void 0 ? void 0 : itemHeight.offsetHeight) * this.limit);
        maxHeight && ((_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.style.setProperty('max-height', `${maxHeight}px`));
        this.loading = false;
      });
    }
  }
  updateAriaAttributes() {
    const anchor = this.getAnchorElement();
    const list = this.listElement.current;
    const isStandalone = this.usage === 'standalone';
    const focusedElement = this.getListItems().find(e => e.id === this.focusedElementId);
    if (anchor) {
      const accessibleElement = anchor.tagName.toLowerCase().startsWith(OWC) && typeof anchor.getAttributeNode === 'function' && anchor.querySelector(NATIVE_ELEMENT_QUERY);
      Object.entries(accessibilityAttributes({
        haspopup: isStandalone,
        controls: list === null || list === void 0 ? void 0 : list.id,
        expanded: this.visible,
        activedescendant: isStandalone ? undefined : this.visible && !(focusedElement === null || focusedElement === void 0 ? void 0 : focusedElement.hidden) ? this.focusedElementId : undefined
      })).forEach(([key, value]) => {
        var _a;
        return (_a = accessibleElement || anchor) === null || _a === void 0 ? void 0 : _a.setAttribute(key, value);
      });
    }
    if (list) {
      Object.entries(accessibilityAttributes({
        activedescendant: isStandalone ? this.focusedElementId : undefined,
        labelledby: isStandalone ? this.getAnchorElementId() : undefined
      })).forEach(([key, value]) => list.setAttribute(key, value));
    }
  }
  updateWidth() {
    if (this.visible && this.anchorWidth) {
      readTask(() => {
        var _a, _b, _c, _d, _e;
        const anchor = this.getAnchorElement();
        const width = ((_a = anchor.getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.width) || ((_b = this.hostElement.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width) || 0;
        (_c = this.hostElement.style) === null || _c === void 0 ? void 0 : _c.setProperty('width', `${width}px`);
        (_d = this.hostElement.style) === null || _d === void 0 ? void 0 : _d.setProperty('min-width', `${width}px`);
        (_e = this.hostElement.style) === null || _e === void 0 ? void 0 : _e.setProperty('max-width', `${width}px`);
      });
    }
  }
  getCardElement() {
    const _a = this.listProps || {},
      {
        ref: listRef,
        onclick: originalListClick,
        onSelectedChange = undefined
      } = _a,
      listProps = __rest(_a, ["ref", "onclick", "onSelectedChange"]);
    return h("owc-card", Object.assign({}, this.cardProps), h("slot", {
      name: "header"
    }), h("owc-list", Object.assign({
      hostRole: "menu"
    }, listProps, {
      ref: el => this.listElement(el, [listRef]),
      onClick: e => this.onListClick(e, originalListClick),
      onSelectedChange: ev => this.onListSelectedChanged(ev, onSelectedChange),
      onFocusChange: e => {
        var _a;
        return this.focusedElementId = ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.id) || undefined;
      }
    }), h("slot", null)), h("slot", {
      name: "footer"
    }));
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      loading: this.loading
    });
    const isStandalone = this.usage === 'standalone';
    return h(Host, Object.assign({
      key: '836b0b2bf13d0e0baf63e18d29755b039c556014',
      class: hostClasses,
      tabIndex: !this.notFocusable && this.visible ? this.component.getInitialTabIndex(0) : -1
    }, accessibility({
      role: isStandalone ? 'region' : 'presentation',
      aria: {
        labelledby: isStandalone ? this.getAnchorElementId() : undefined
      }
    })), h("owc-popover", {
      key: 'df608eaf977a7c476bdb3754cee1699463fb0c9a',
      anchor: this.anchor,
      visible: this.visible,
      strategy: this.strategy,
      offset: this.offset,
      tabIndex: -1,
      position: this.position,
      trigger: this.trigger,
      disableAccessibility: true,
      preventOverflow: this.preventOverflow,
      onVisibleChange: e => this.visible = e.detail,
      onClickOutside: () => this.clickOutside.emit()
    }, this.usage === 'standalone' ? h("owc-focus-trap", {
      active: this.visible,
      autoFocusType: () => {
        var _a;
        return (_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.getKeydownManager().then(manager => {
          manager.setCurrentIndex(this.autoFocusTo === 'last' ? manager.getItems().length - 1 : 0);
          return this.listElement.current;
        });
      },
      exitWithEscape: true,
      onExit: () => this.visible = false
    }, this.getCardElement()) : this.getCardElement()));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "limit": ["onLimitChange"],
      "visible": ["visibleWatcher"],
      "anchor": ["onAnchorChanged"],
      "loading": ["onLoadingChanged"]
    };
  }
  static get style() {
    return OwcMenuStyle0;
  }
}, [6, "owc-menu", {
  "hostId": [1537, "id"],
  "anchor": [1],
  "usage": [513],
  "position": [513],
  "limit": [514],
  "visible": [1540],
  "notFocusable": [516, "not-focusable"],
  "preventVisibilityChangeOnAnchorBlur": [4, "prevent-visibility-change-on-anchor-blur"],
  "autoCloseOnSelection": [4, "auto-close-on-selection"],
  "strategy": [513],
  "trigger": [513],
  "offset": [16],
  "listProps": [16],
  "cardProps": [16],
  "anchorWidth": [4, "anchor-width"],
  "preventOverflow": [4, "prevent-overflow"],
  "noKeydownManager": [4, "no-keydown-manager"],
  "focusedElementId": [32],
  "autoFocusTo": [32],
  "loading": [32]
}, undefined, {
  "limit": ["onLimitChange"],
  "visible": ["visibleWatcher"],
  "anchor": ["onAnchorChanged"],
  "loading": ["onLoadingChanged"]
}]);
__decorate([Context(CTX_KEYS.MENU.STATUS)], Menu.prototype, "ctxMenuStatus", void 0);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-menu", "owc-card", "owc-focus-trap", "owc-icon", "owc-list", "owc-popover"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-menu":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Menu);
        }
        break;
      case "owc-card":
        if (!customElements.get(tagName)) {
          defineCustomElement$5();
        }
        break;
      case "owc-focus-trap":
        if (!customElements.get(tagName)) {
          defineCustomElement$4();
        }
        break;
      case "owc-icon":
        if (!customElements.get(tagName)) {
          defineCustomElement$3();
        }
        break;
      case "owc-list":
        if (!customElements.get(tagName)) {
          defineCustomElement$2();
        }
        break;
      case "owc-popover":
        if (!customElements.get(tagName)) {
          defineCustomElement$1();
        }
        break;
    }
  });
}
export { Menu as M, defineCustomElement as d };