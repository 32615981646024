import { p as proxyCustomElement, H, d as createEvent, h, c as Host } from './p-92c4a1ea.js';
import { V as findElementInEventPath, Q as stopEvent, ac as KeydownManager, ad as KeydownTemplate, a6 as FocusType, aa as KeydownLifecycleMethod, U as stopImmediatePropagation } from './p-a57042e3.js';
import { b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const dotsCss = ".sc-owc-dots-h{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;outline:none;isolation:isolate;overflow-y:visible !important}.owc-dots--direction-vertical.sc-owc-dots-h{-ms-flex-direction:column;flex-direction:column}.owc-dots--disabled.sc-owc-dots-h{pointer-events:none}";
const OwcDotsStyle0 = dotsCss;
const DOT_ITEM_SELECTOR = 'owc-dot';
const Dots = /*@__PURE__*/proxyCustomElement(class Dots extends H {
  constructor() {
    super();
    this.__registerHost();
    this.selectedChange = createEvent(this, "selectedChange", 3);
    this.onDotItemClick = event => {
      const item = findElementInEventPath(event, DOT_ITEM_SELECTOR);
      if (item && !item.disabled) {
        stopEvent(event);
        this.setSelected(this.getItems().findIndex(el => item.isSameNode(el)));
        this.component.keydownManager().setCurrentItem(item, {
          event
        }, false);
      }
    };
    this.setSelected = newValue => {
      if (newValue !== this.selected) {
        if (this.controlled) {
          this.selectedChange.emit(newValue);
        } else {
          this.selected = newValue;
        }
      }
    };
    this.getItems = () => Array.from(this.hostElement.querySelectorAll(DOT_ITEM_SELECTOR));
    this.getSelectableItems = () => this.getItems().filter(item => !item.disabled);
    this.propagateAttributesToItems = () => {
      this.getItems().forEach((el, index) => {
        el.tabIndex = -1;
        el.selected = index === this.selected;
        el.controlled = this.controlled;
        el.parentDisabled = this.disabled;
      });
    };
    this.component = new ComponentUtils(this).withKeydownManager([KeydownManager.Templates[KeydownTemplate.GROUP], {
      focusType: FocusType.BY_CLASS,
      selector: () => this.getSelectableItems(),
      actualSelectedItem: () => this.getItems().find((item, index) => !item.disabled && this.selected === index),
      lifecycleHandlers: {
        [KeydownLifecycleMethod.ITEM_CHANGE]: ({
          items,
          current
        }) => {
          var _a, _b;
          this.activeDotId = ((_a = items[current]) === null || _a === void 0 ? void 0 : _a.id) || undefined;
          (_b = this.hostElement) === null || _b === void 0 ? void 0 : _b.focus();
        }
      }
    }]);
    this.disabled = false;
    this.controlled = false;
    this.selected = undefined;
    this.direction = 'horizontal';
    this.activeDotId = undefined;
  }
  onSelectedChanged(newValue, oldValue) {
    newValue !== oldValue && !this.controlled && this.selectedChange.emit(newValue);
  }
  onSelectedChange(event) {
    const item = findElementInEventPath(event, DOT_ITEM_SELECTOR);
    if (item) {
      const index = this.getItems().findIndex(el => item.isSameNode(el));
      stopImmediatePropagation(event);
      stopEvent(event);
      event.detail && this.setSelected(index);
    }
  }
  componentDidLoad() {
    var _a;
    this.activeDotId = (_a = this.getSelectableItems().find(e => e.selected) || this.getSelectableItems()[0]) === null || _a === void 0 ? void 0 : _a.id;
    this.propagateAttributesToItems();
  }
  componentWillRender() {
    this.propagateAttributesToItems();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      [`direction-${this.direction || 'horizontal'}`]: true
    });
    return h(Host, Object.assign({
      key: '075f326721605d5eb452b1b1f47c2157dc8310cd',
      class: hostClasses,
      tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex(0),
      onClick: this.onDotItemClick
    }, accessibility({
      role: 'tablist',
      aria: {
        activedescendant: this.activeDotId
      }
    })), h("slot", {
      key: '6c53924a2a56dde6d5cb78b75fbfcf6ace051419'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "selected": ["onSelectedChanged"]
    };
  }
  static get style() {
    return OwcDotsStyle0;
  }
}, [6, "owc-dots", {
  "disabled": [516],
  "controlled": [516],
  "selected": [1538],
  "direction": [513],
  "activeDotId": [32]
}, [[0, "selectedChange", "onSelectedChange"]], {
  "selected": ["onSelectedChanged"]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-dots"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-dots":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Dots);
        }
        break;
    }
  });
}
const OwcDots = Dots;
const defineCustomElement = defineCustomElement$1;
export { OwcDots, defineCustomElement };